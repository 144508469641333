import Button from "components/general/buttonv2";
import SvgIcon from "components/general/svgIcon";
import HeroVideo from "components/home/heroVideo";
import HomeHeroTitle from "components/home/homeHeroTitle";
import { downloadLinksArray, downloadsDisabled } from "data/variables";
import Link from "next/link";
import { useState, useEffect } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function getDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  return "unknown";
}
const Hero = () => {
  const [downloadText, setDownloadText] = useState("Get Redact");
  useEffect(() => {
    const device = getDevice();
    if (device === "iOS") {
      // Redirect to App Store
      setDownloadText("Get Redact for iPhone");
    } else if (device === "Android") {
      // Redirect to Google Play Store
      setDownloadText("Get Redact for Android");
    }
  }, []);
  return (
    <section className="hero__wrapper" style={{ background: "var(--background-color)" }}>
      <StyledContainer>
        <HomeHeroTitle />
        <div
          style={{
            marginBottom: "48px",
            fontSize: "1.1rem",
            lineHeight: "1.5",
            maxWidth: "600px",
            textAlign: "center",
          }}
        >
          The only platform that allows you to automatically clean up your old posts from services
          like Twitter, Reddit, Facebook, Discord and more all in one place
        </div>
        <div className="hero__button_row">
          <Button
            disabled={downloadsDisabled}
            to="/download"
            data-action-name="Home: Download"
            buttonBuddy={true}
            dataAttr="hero-download"
          >
            {downloadText}
          </Button>
        </div>

        <div className="hero__svg_wrapper hero__svg_wrapper--auto">
          <HeroVideo />
        </div>
        <div className="hero__button_row__icons">
          {downloadLinksArray.map((value) => {
            if (value.external) {
              return (
                <a
                  key={"" + value.link + value.external + value.icon}
                  className={`hero__button_row__icon ${
                    value.disabled ? "hero__button_row__icon--disabled" : ""
                  }`}
                  href={value.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgIcon name={value.icon} />
                </a>
              );
            }
            return (
              <Link
                href="/download"
                passHref
                key={"" + value.icon}
                className={`hero__button_row__icon ${
                  value.disabled ? "hero__button_row__icon--disabled" : ""
                }`}
              >
                <SvgIcon name={value.icon} />
              </Link>
            );
          })}
        </div>
        <div className="hero__subtext">Available for Windows, MacOS, and Linux today</div>
      </StyledContainer>
    </section>
  );
};

export default Hero;
