import styled from "styled-components";

const Video = ({ className, src }) => {
  return (
    <video playsInline autoPlay={true} muted loop className={className}>
      <source src="/videos/herovideonew3.webm" type="video/webm" />
      <source src="/videos/herovideonew3.mp4" type="video/mp4" />
    </video>
  );
};
const StyledVideo = styled(Video)`
  width: 100%;
  height: auto;
  position: relative;
  background: transparent url("/images/heropreview3.jpg") no-repeat 0 0;
  background-size: cover;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const CtaGif = () => {
  return <StyledVideo />;
};

export default CtaGif;
